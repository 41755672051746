<template>
  <div>
    <div class="nav">
      <h1>资料管理/客户管理</h1>
    </div>
    <div class="search">
      <div class="left">
        <div class="box">
          <el-input
            placeholder="请输入内容"
            style="width:300px"
            v-model="username"
            clearable
            class="input-with-select"
          >
            <template slot="prepend">姓名</template>
          </el-input>
        </div>
      </div>
      <div class="right">
        <el-button type="" @click="getList()">搜索客户</el-button>
        <el-button type="primary" @click="saveUser()">新增客户</el-button>
      </div>
    </div>

    <div class="result">
      <span></span>
      <p>查询结果</p>
    </div>

    <div class="table">
      <el-table :data="tableData" style="width: 100%" header-cell-style="background: #f5f7fa;">
        <el-table-column align="center" prop="name" :show-overflow-tooltip="true" label="名称" fixed="left"></el-table-column>
        <el-table-column align="center" prop="phone" :show-overflow-tooltip="true" label="手机号"></el-table-column>
        <el-table-column align="center" prop="card" :show-overflow-tooltip="true" label="银行卡"></el-table-column>
        <el-table-column align="center" prop="opening" :show-overflow-tooltip="true" label="开户行"></el-table-column>
        <el-table-column align="center" prop="prestore_price" :show-overflow-tooltip="true" label="预存金额"></el-table-column>
        <el-table-column align="center" prop="withdraw_price" :show-overflow-tooltip="true" label="可提现金额"></el-table-column>
        <el-table-column align="center" prop="sum" :show-overflow-tooltip="true" label="冻结金额"></el-table-column>
        <el-table-column align="center" prop="createtime" width="170" label="创建时间"></el-table-column>
        <!-- <el-table-column align="center" prop="sortOrder" label="排序" width="100"></el-table-column> -->
        <el-table-column align="center" prop="address" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                @click.native="editRecharge(scope.row,1)"
                effect="dark"
                content="充值"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-s-finance"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="editRecharge(scope.row,2)"
                effect="dark"
                content="提现"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-s-grid"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="checkGoods(scope.row)"
                effect="dark"
                content="买卖材料明细"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-s-goods"></i>
                </p>
              </el-tooltip>
              
              <el-tooltip
                class="item"
                @click.native="editDownload(scope.row)"
                effect="dark"
                content="编辑用户"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-edit"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="delList(scope.row.id)"
                effect="dark"
                content="删除用户"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-delete-solid"></i>
                </p>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[10, 50, 100, 150, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <operationUser :dialogVisible="dialogVisible" :data="con" @outClose="dialogVisible = false" />

    <el-dialog
      :title="type == 1 ? '充值' : '提现'"
      :visible.sync="rechargeDialogVisible"
      width="500px"
    >
      <div class="from">
        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px;">
              名称
              <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
            </p>
            <el-input v-model="con.name" disabled placeholder="请输入姓名"></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px;">
              手机号
              <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
            </p>
            <el-input v-model="con.phone" disabled placeholder="请输入内容"></el-input>
          </div>
        </div>

        <div class="list-dialog">
          <div class="list">
            <p style="margin-bottom: 10px;">
              {{ type == 1 ? '充值金额' : '提现金额' }}
              <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
            </p>
            <el-input v-model="amount" placeholder="请输入内容"></el-input>
          </div>
        </div>

      </div>

      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="rechargeDialogVisible  = false">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>

     <!-- 定价单 -->
     <el-dialog
      title="买卖料明细"
      :visible.sync="shopDialogVisible"
      :before-close="outClose"
      width="800px"
    >
      <h3 style="color: #000;font-size: 23px;text-align: center;height: 54px;">买卖料明细</h3>
      <div class="">
        <!-- <p style="color:blue">客户：{{ orderInfo.name }}</p>
        <p style="color:blue">可用金额：{{ withdraw }}</p> -->
      <div class="top" style="display: flex;justify-content: space-around; margin-bottom: 20px;">
        <div class="" style="width:50%">
          <p style="font-weight: 600; font-size: 14px;color: #000;">客户：{{ userInfo.name }}</p>
          <p style="font-weight: 600; font-size: 14px;color: #000;">已收定金：{{ userInfo.withdraw_price }}</p>
          <p style="font-weight: 600; font-size: 14px;color: #000;">可提现资金：{{ withdraw }}</p>
        </div>
        <div class="" style="width:50%">
          <!-- <p style="font-weight: 500; font-size: 16px;color: #000;">交易单号：{{ userInfo }}</p> -->
          <p style="font-weight: 600; font-size: 14px;color: #000;text-align: right;margin-top:20px">锁定定金：{{ earnestMoney }}</p>
          <p style="font-weight: 600; font-size: 14px;color: #000;text-align: right;">浮动盈亏：{{ sumdata }}</p>
        </div>
      </div>
      </div>
      <table border="1" cellpadding="15px" cellspacing="0" style="width: 100%;margin: auto;text-align: center">
        <tbody>
          <!-- <tr>
            <td colspan="4" style="font-size: 11px;font-weight: 1000;">客户名称</td>
            <td colspan="5" style="font-size: 11px;font-weight: 1000;">{{ orderInfo.name }}</td>
          </tr> -->
          <!-- <tr>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">已收定金</td>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">{{ userInfo.withdraw_price }}</td>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">锁定定金</td>
            <td colspan="3" style="font-size: 11px;font-weight: 1000;">{{ earnestMoney  }}</td>
          </tr>

          <tr>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">可提现余额</td>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">{{ withdraw }}</td>
            <td colspan="2" style="font-size: 11px;font-weight: 1000;">浮动盈亏</td>
            <td colspan="3" style="font-size: 11px;font-weight: 1000;">{{ sumdata }}</td>
          </tr> -->

          <tr>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">交易单号</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">日期</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">原料名称</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">未结重量</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">交易类型</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">预定金价格</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">最新金价</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">备注</td>
          </tr>

<!-- {{ orderList }} -->
          <tr v-for="list in orderList" :key="list">
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ list.trading_code }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ list.time }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ list.ingredients_name }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ list.weight }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ list.type == 1 ? "定价销售" : "定价回购" }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ list.order_pricer }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ list.price }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;"> {{ list.remark }}</td>
          </tr>

          <!-- <tr>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.ingredients_name }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.weight }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.order_pricer }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.order_pricer }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.earnest }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{  orderInfo.order_status == 1 ? "定价销售" : "定价回购" }}</td>
            <td style="font-size: 13px;font-weight: 800;color: #000000;">{{ orderInfo.remark }}</td>
          </tr>

          <tr>
            <td colspan="1" style="font-size: 11px;font-weight: 1000;">合计</td>
            <td style="font-size: 11px;font-weight: 800;">{{ orderInfo.weight }}</td>
            <td style="font-size: 11px;font-weight: 800;color: #000000;"></td>
            <td style="font-size: 11px;font-weight: 800;color: #000000;">{{ orderInfo.order_pricer }}</td>
            <td style="font-size: 11px;font-weight: 800;color: #000000;">{{ orderInfo.earnest }}</td>
            <td style="font-size: 11px;font-weight: 800;color: #000000;"></td>
          </tr> -->

          <tr>
            <td colspan="3" style="font-size: 11px;font-weight: 1000;">合计</td>
            <td style="font-size: 11px;font-weight: 800;">{{ closeValue }}</td>
            <td colspan="4" style="font-size: 11px;font-weight: 800;color: #000000;"></td>
          </tr>
        </tbody>
      </table>
      <br>
      <!-- <p style="margin: 0 0 20px;font-weight:600;line-height: 26px;">
        温馨提示：<br>
        1：购销双方本着公平交易，互利互惠原则达成购销协议 <br>
        2：客户承诺以上出售所有物品是合法所有，不是赃物或违法所得，本人愿意承担经济和法律责任 <br>
        3：来料提料请提前一天通知 <br><br>
      </p> -->
      <p style="font-weight: 600; font-size: 14px;color: #000;padding-bottom:10px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;复核：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  客户签字： &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  出纳：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   主管：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    </el-dialog>
  </div>
</template>

<script>
import operationUser from "./components/operationUser.vue";
import { getList,delList,recharge,withdraw,getProduct } from "@/api/user"
export default {
  components: {
    operationUser
  },
  inject: ['reload'],
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 50,
      item: [],
      tableData: [],
      classifyList: [],
      orderList: [],
      dialogVisible: false,
      rechargeDialogVisible: false,
      shopDialogVisible: false,
      con: {},
      orderInfo: {},
      amount: "",
      username: '',
      type: 0,
      orderPrice: 0,
      userInfo: {},
      xiaoshou: 0,
      sumdata: 0,
      withdraw: 0
    };
  },
  created() {
    this.getList();
  },
  computed: {
  
    earnestMoney() {
      var sum = 0;
      this.orderList.forEach(list => {
        sum += parseInt(list.earnest)
      })
      return sum
    },
    closeValue() {
      var sum = 0;
      this.orderList.forEach(list => {
        sum += parseInt(list.weight)
      })
      return sum
    }
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        this.getList();
        this.amount = ''
      }
    },
    rechargeDialogVisible(status) {
      if(!status) {
        this.amount = ''
      }
    },
    shopDialogVisible(status) {
      if(status) {
        var xiaoshou = 0
        var huigou = 0
        
        this.orderList.forEach(list => {
          if(list.type == 1) { // 销售 = 后面（最新金价 - 预定金价格）
            xiaoshou += (list.price - list.order_pricer) * parseFloat(list.weight) 
          }
          if(list.type == 2) { // 回购 = 前面（预定金价格 - 最新金价）
            huigou += (list.order_pricer - list.price) * parseFloat(list.weight)
          }
        })

        this.sumdata = (xiaoshou + huigou).toFixed(2)
        // 定金 - 锁定 + 浮动
        this.withdraw = (this.userInfo.withdraw_price - this.earnestMoney) + parseInt(this.sumdata)
      }
    }
  },
  methods: {
    checkGoods(list) {
      this.userInfo = list
      getProduct(list.id).then(res => {
          this.orderList = res.data.list
          this.shopDialogVisible = true
        })
    },  
    // 提现充值
    editRecharge(data,type) {
      this.type = type
      this.con = JSON.parse(JSON.stringify(data));
      this.rechargeDialogVisible = true
    },
    submit() {
      if(this.type == 1) {
        recharge(this.con.id,{
          type: this.type,
          amount: this.amount
        }).then(res => {
          if(res.code == 200) {
            this.getList()
            this.rechargeDialogVisible = false
            this.$message.success('充值成功')
          }
        })
      } else {
        withdraw(this.con.id,{
          type: this.type,
          amount: this.amount
        }).then(res => {
          if(res.code == 200) {
            this.getList();
            this.rechargeDialogVisible = false
            this.$message.success('提现成功')
          }
        })
      }
    },
    saveUser() {
      this.dialogVisible = true;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getList();
    },

    editDownload(data) {
      this.con = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
    },
    delList(id) {
      let that = this;
      this.$confirm("您正在删除用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delList(id).then(res => {
            console.log(res);
            that.dialogVisible = false;
            that.$message.success("删除成功");
            that.reload();
          });
        })
        .catch(() => {
          that.$message.info("删除失败");
        });
    },
    getList() {
      getList(this.pageNum,this.pageSize,this.username).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total
      });
    }
  }
};
</script>

<style lang="less" scoped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
/deep/ .el-dialog__body {
  padding: 10px 20px;
}


.list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 13px;
  p {
    font-size: 15px;
    color: rgba(125, 133, 146, 1);
    margin-bottom: 10px;
  }
  .el-input {
    width: 100%;
  }
}


.list-dialog {
  //   margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .list {
    width: 100%;
    .el-input {
      width: 100%;
    }
  }
}


.search {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .left {
    width: 60%;
    display: flex;
    align-items: flex-end;
    .box {
      display: flex;
      /deep/ .el-input {
        margin-right: 20px;
        //   margin-left: 20px;
      }
    }
    /deep/ .el-select {
      width: 250px;
      margin-right: 20px;
    }
    .el-button {
      margin-left: 20px;
    }
    .input-with-select {
      width: 600px;
    }
  }
  .right {
    // width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .box {
      display: flex;
      align-items: center;
    }
  }
}

.table {
  margin-top: 20px;
  .shop {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    p {
      position: relative;
    }
  }
}

td {
  height: 25px;
}
</style>